exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appointment-tsx": () => import("./../../../src/pages/appointment.tsx" /* webpackChunkName: "component---src-pages-appointment-tsx" */),
  "component---src-pages-centers-tsx": () => import("./../../../src/pages/centers.tsx" /* webpackChunkName: "component---src-pages-centers-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-mentions-tsx": () => import("./../../../src/pages/legalMentions.tsx" /* webpackChunkName: "component---src-pages-legal-mentions-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-brands-tsx": () => import("./../../../src/templates/brands.tsx" /* webpackChunkName: "component---src-templates-brands-tsx" */),
  "component---src-templates-centers-tsx": () => import("./../../../src/templates/centers.tsx" /* webpackChunkName: "component---src-templates-centers-tsx" */)
}

